import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
import Article from "../../components/Article";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

const StoryArticle = ({ children, title, name, date, imageDesktop, imageMobile }) => {
    const {
        anaUndPaulStoryArticleImageMobile,
        anaUndPaulStoryArticleImageDesktop,
        danielaStoryArticleImageMobile,
        danielaStoryArticleImageDesktop,
        beateStoryArticleImageMobile,
        beateStoryArticleImageDesktop,
    } = useStaticQuery(graphql`
        query {
            anaUndPaulStoryArticleImageMobile: file(
                relativePath: { eq: "success-stories/images/mobile/anna-und-paul.jpeg" }
            ) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
            anaUndPaulStoryArticleImageDesktop: file(
                relativePath: { eq: "success-stories/images/desktop/anna-und-paul.jpeg" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                }
            }
            danielaStoryArticleImageMobile: file(relativePath: { eq: "success-stories/images/mobile/daniela.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
            danielaStoryArticleImageDesktop: file(relativePath: { eq: "success-stories/images/desktop/daniela.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                }
            }
            beateStoryArticleImageMobile: file(relativePath: { eq: "success-stories/images/mobile/beate.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
            beateStoryArticleImageDesktop: file(relativePath: { eq: "success-stories/images/desktop/beate.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
                }
            }
        }
    `);
    const getStoryArticleImage = (name) => {
        let images;
        switch (name) {
            case "Anna und Paul":
                images = withArtDirection(getImage(anaUndPaulStoryArticleImageDesktop), [
                    {
                        media: `(max-width: ${breakpoints.tabletMin})`,
                        image: getImage(anaUndPaulStoryArticleImageMobile),
                    },
                ]);
                break;
            case "Daniela":
                images = withArtDirection(getImage(danielaStoryArticleImageDesktop), [
                    {
                        media: `(max-width: ${breakpoints.tabletMin})`,
                        image: getImage(danielaStoryArticleImageMobile),
                    },
                ]);
                break;
            default:
                images = withArtDirection(getImage(beateStoryArticleImageDesktop), [
                    {
                        media: `(max-width: ${breakpoints.tabletMin})`,
                        image: getImage(beateStoryArticleImageMobile),
                    },
                ]);
                break;
        }
        return images;
    };
    return (
        <Article hasMarginTop>
            <Image
                image={getStoryArticleImage(name)}
                alt={name}
                layout="fullWidth"
                imgStyle={{ maxWidth: "100%", width: "100%" }}
            />
            <Title>{title}</Title>
            <Wrapper>
                <Data>
                    <h3>{name}</h3>
                    <div>{date}</div>
                </Data>
                <Content>{children}</Content>
            </Wrapper>
        </Article>
    );
};

const multiColBreakMin = "900px";

const Image = styled(GatsbyImage)`
    display: block;
    max-width: 100%;
    margin-bottom: 50px;
    margin-top: -130px;
    width: 100%;

    @media (min-width: ${breakpoints.tabletBigMin}) {
        margin-top: -280px;
    }
    @media (min-width: ${breakpoints.desktopMin}) {
        margin-top: -250px;
    }
    @media (max-width: ${breakpoints.tabletMin}) {
        height: 380px;
    }
`;

const Title = styled.h1`
    color: #2563eb;
    font-weight: 300;

    @media (min-width: ${breakpoints.desktopMin}) {
        margin-left: 34%;
    }
`;

const Wrapper = styled.div`
    @media (min-width: ${breakpoints.desktopMin}) {
        display: flex;
        justify-content: space-between;
    }
`;

const Data = styled.div`
    margin-bottom: 40px;

    @media (min-width: ${breakpoints.desktopMin}) {
        width: 30%;
    }

    h3 {
        color: #374151;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 2px;
    }
    div {
        color: #6b7280;
        font-size: 12px;
    }
`;

const Content = styled.div`
    @media (min-width: ${multiColBreakMin}) {
        column-count: 2;
        column-gap: 6%;
    }
    @media (min-width: ${breakpoints.desktopMin}) {
        width: 66%;
        column-count: 2;
        column-gap: 6%;
    }
`;

export default StoryArticle;
