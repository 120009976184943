import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import StoryArticle from "../../components/successStories/StoryArticle";
import { SUCCESS_STORIES_LIST } from "../../constants/successStoriesList";

const Daniela = () => {
    const key = "Daniela";
    const { name, date, image, storyShort } = SUCCESS_STORIES_LIST[key];
    const dateParts = date.split(".");
    const isoDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0] + 1).toISOString().substring(0, 10);

    const { mobile, desktop } = image;

    return (
        <Layout>
            <MetaComponents title={`${name} | miracl`} description={storyShort} />
            <GreyHeader />
            <StoryArticle
                name={name}
                date={date}
                title={"Es ist nie einfach ..."}
                imageDesktop={desktop}
                imageMobile={mobile}
            >
                <p>
                    ... wenn eine langjährige Beziehung zu Ende geht. Man steht nicht nur fassungslos vor dem
                    Scherbenhaufen gemeinsamer Zukunftsvorstellungen und muss nun alleine neue Perspektiven für das
                    eigene Leben entwickeln; während der Alltag um einen munter weitergeht, man arbeiten und
                    gegebenenfalls sogar Betreuungspflichten für gemeinsame Kinder wahrnehmen muss, die ihrerseits unter
                    dem Beziehungsende leiden. In vielen Fällen kommen auch finanzielle Sorgen hinzu, die das Ende der
                    Partnerschaft zum mühseligen bürokratischen Akt werden lassen. Genau diese Erfahrung musste Daniela
                    [Name geändert; Anm. d. Redaktion] machen, als ihre Beziehung vor einigen Monaten vor dem Aus stand.
                </p>
                <p>
                    Sie war mit einem Schlag alleinerziehende Mutter und lebte in einem Haus, dessen laufende
                    Finanzierung sie sich ursprünglich mit ihrem Partner geteilt hatte. Ein Umzug kam für sie allerdings
                    nicht in Frage – dazu fühlten sich ihre Kinder im Haus zu wohl, hatten ihren gesamten Freundeskreis
                    in der unmittelbaren Umgebung und konnten auch Schule und Fußballplatz selbstständig mit dem Rad
                    erreichen. Gerade die Autonomie der Kinder im gewohnten Umfeld war es, die Daniela in ihrer neuen
                    Rolle als Alleinerziehende entscheidend entlasten würde. Sie musste das Haus also um jeden Preis
                    behalten. Eine Umschuldung musste her, mit der sie den Kredit auf sich alleine umschreiben könnte.
                    So klar ihr dieser Plan vor Augen stand: Die Umsetzung erwies sich als überaus schwierig, ja schien
                    anfangs sogar unmöglich. Zunächst weigerte sich die Bank, die den laufenden Kredit gewährt hatte,
                    diesen auf Daniela umzuschulden. Den Grund, der ihr genannt wurde, haben schon zahlreiche
                    Kreditnehmer:innen zu hören bekommen und zahllose weitere werden ihn noch hören: “Sorry, wir
                    verstehen Ihre Situation, aber Ihrerer Haushaltsrechnung entnehmen wir, dass Sie für einen Kredit
                    einfach zu wenig finanziellen Spielraum haben.” Bei ihrer Hausbank exakt dasselbe Schauspiel: Man
                    könne sich vorstellen, wie schwer es sein muss, nun alleinerziehend zu sein, es tue einem leid: Aber
                    eine Bank sei nun mal eine Bank und müsse nach ökonomischen Gesichtspunkten entscheiden und die
                    sprechen nun einmal gegen eine Umschuldung. Daniela hätte an dieser Stelle einfach aufgeben können;
                    sie hätte sich mit der Einsicht begnügen können, dass in einer modernen, ausdifferenzierten
                    Gesellschaft Banken ihr Handeln ausschließlich an wirtschaftlichen Kriterien orientieren und die
                    private Situation ihrer Kund:innen notwendigerweise ausblenden. Hat sie aber nicht. Stattdessen hat
                    sie sich an miracl gewendet, dessen Team nach der Prüfung ihrer Haushaltsrechnung zwar bestätigte,
                    dass es schwer werde, ein Kreditinstitut zu finden, das sich bereiterklärt, eine Umschuldung
                    durchzuführen; dass es eine Umschuldung mit ihren finanziellen Mittel allerdings möglich ist, wenn
                    auch knapp. Der Rest ist ein kleines Stück Finanzierungsgeschichte: Mit der Unterstützung von miracl
                    hat Daniela nicht nur die Umschuldung erhalten. Ihr neuer Kredit hat auch noch niedrigere Raten.
                </p>
            </StoryArticle>
            <BreadcrumbList page={key}></BreadcrumbList>
            <ArticleStructuredData
                page={key}
                heading={name}
                description={storyShort}
                datePublished={isoDate}
                dateModified={isoDate}
            />
        </Layout>
    );
};

export default Daniela;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
